import React from "react";
import ReactDOM from "react-dom/client";
import "antd/dist/antd.min.css";
import "swiper/css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Helmet } from "react-helmet";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Helmet>
      <title>
        Home - Daffodil International University Computer and Programming Club
        (DIU CPC)
      </title>
      <meta
        name="description"
        content="Welcome to the official website of the Daffodil International University Computer & Programming Club (DIU CPC). Stay informed about our latest events, workshops, and club activities."
      />
      <meta
        property="og:title"
        content="Home | DIU CPC - Daffodil International University Computer & Programming Club"
      />
      <meta
        property="og:description"
        content="Explore the official website of the DIU Computer & Programming Club. Discover upcoming events, workshops, and activities organized by our vibrant community."
      />
      <meta property="og:image" content="%PUBLIC_URL%/images/DIUCPC-logo.png" />
      <meta property="og:url" content="https://cpc.daffodilvarsity.edu.bd/" />
    </Helmet>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
